import React, { useEffect } from "react"
import { PageMeta } from "components/Common/Header";

const AmazingThailand = () => {

  useEffect(() => {
    const userAgent = navigator.userAgent;

    // Android detection
    if (/android/i.test(userAgent)) {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.tat.amazingthailand';
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
      window.location.href = 'https://apps.apple.com/th/app/amazing-thailand/id6736444009';
    } else {
      window.location.href = 'https://apps.apple.com/th/app/amazing-thailand/id6736444009';
    }
  }, []);

  return (
    <React.Fragment>
      <div className="" style={{ backgroundColor: '#fff', position: 'relative', height: '100vh', overflow: 'hidden' }}>
        <PageMeta title="TAT Event | Graffity" />

        <img
          src="https://medias.thansettakij.com/uploads/images/contents/w1024/2025/03/YyUbVWAYaizN7ehDq89U.webp"
          alt="Amazing Thailand"
          style={{ width: '100%', height: 'auto' }}
        />
      </div>
    </React.Fragment>
  )
}

export default AmazingThailand
