import React, { Suspense, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import {
    Container,
    Card,
    Row,
    Col,
} from "reactstrap";
import { ARAnchorType, usePointCloudData } from "../data";
import { WorldAnchorEditor } from "./WorldAnchorEditor/WorldAnchorEditor";
import ProjectEditorHeader from "./ProjectEditorHeader";
import { ObjectAnchorEditor } from "./ObjectAnchorEditor/ObjectAnchorEditor"


import "./Editor.css";
import { ARContentProperties } from "./ARContentProperties/ARContentProperties";
import { useEditorState } from "./editorHook";
import { ToolsBar } from "./common/ToolsBar/ToolsBar";
import { ControlMode } from "./common/TransformControl/TransformControl";
import { PageMeta } from "components/Common/Header";
import { isConsoleAccessAllowed } from "constants/const";
import { connect } from "react-redux";
import { ApplicationState } from "store/data";
import { OrganizationType } from "store/organization/types";
import { Dispatch } from "redux";
import LeftSideMenu from "./LeftSideMenu/LeftSideMenu";
import { EditorState } from "store/editor/types";
import { PointCloudEditor } from "./PointCloudEditor/PointCloudEditor";

interface StateProps {
    organization: OrganizationType
    editorState: EditorState
}

interface DispatchProps {
    dispatch: Dispatch
}

type Props = StateProps & DispatchProps

const ProjectEditor = ({ organization, editorState, dispatch }: Props) => {
    const { projectId } = useParams();
    const [urlParams] = useSearchParams();

    const [controlMode, setControlMode] = useState<ControlMode>(ControlMode.Translate)
    const detectionImageId = urlParams.get('detection_image_id');
    const faceAnchorId = urlParams.get('face_anchor_id');
    const pointCloudId = urlParams.get('point_cloud_id');
    const shouldAllowAccess = isConsoleAccessAllowed(organization);
    const [place, isFetching] = usePointCloudData(pointCloudId || "")

    const {
        onUndo,
        onRedo,
        enableUndo,
        enableRedo,
        handleUpdateARContent,
        handleUpdateARContentState,
        handleAddARContentToState,
        handleRemoveARContent,
        handleCreatePoi,
        handleUpdatePoi,
        handleUpdatePoiState,
        handleRemovePoi,
        handlePublishProject,
        handleEditorRefresh,
        project,
        displayPois,
        displayArContents: arContents,
    } = useEditorState(
        projectId,
        faceAnchorId,
        detectionImageId,
        pointCloudId,
        shouldAllowAccess,
        dispatch,
        editorState,
    )

    // add upload manager modal
    return (
        <div id="project-editor">
            {(project.isFinished && arContents) &&
                <Suspense fallback={null}>
                    <ProjectEditorHeader
                        project={project.data}
                        arContents={arContents}
                        onPublishProject={handlePublishProject}
                        place={place || undefined}
                    />
                    <PageMeta title={`${project.data.name} | Graffity Console`} />
                    <Container fluid className="h-100" >
                        <Row className="h-100 g-0" >
                            <Col lg={2} className="ar-menu-card card-container">
                                <LeftSideMenu
                                    project={project.data}
                                    arContents={arContents}
                                    pois={displayPois}
                                    onAddARContent={handleAddARContentToState}
                                    onUpdateARContent={handleUpdateARContentState}
                                    onCreatePoi={handleCreatePoi}
                                    onEditorRefresh={handleEditorRefresh}
                                    place={place}
                                />
                            </Col>
                            <Col lg={8} className="card-container align-items-center">
                                <Card>
                                    <ToolsBar
                                        selectedControlMode={controlMode}
                                        onControlModeChange={(newControlMode) => { setControlMode(newControlMode) }}
                                        onUndo={onUndo}
                                        onRedo={onRedo}
                                    />
                                    {/* When an AnchorEditor is in separate comp., rendering tree will be refresh too much onChange ARContent props */}
                                    {(project.data.lastOpenedAnchorType === ARAnchorType.WorldAnchor) &&
                                        pointCloudId === null ?
                                        <WorldAnchorEditor
                                            project={project.data}
                                            arContents={arContents}
                                            onArContentUpdate={handleUpdateARContent}
                                            onArContentStateUpdate={handleUpdateARContentState}
                                            controlMode={controlMode}
                                        /> : place && <PointCloudEditor
                                            arContents={arContents}
                                            project={project.data}
                                            pois={displayPois}
                                            pointCloud={place}
                                            onArContentUpdate={handleUpdateARContent}
                                            onArContentStateUpdate={handleUpdateARContentState}
                                            onPoiUpdate={handleUpdatePoi}
                                            onPoiStateUpdate={handleUpdatePoiState}
                                            controlMode={controlMode}
                                        />
                                    }
                                    {(project.data.lastOpenedAnchorType === ARAnchorType.ImageAnchor) &&
                                        <ObjectAnchorEditor
                                            arContents={arContents}
                                            anchorType={ARAnchorType.ImageAnchor}
                                            onArContentUpdate={handleUpdateARContent}
                                            onArContentStateUpdate={handleUpdateARContentState}
                                            controlMode={controlMode}
                                        />
                                    }
                                    {(project.data.lastOpenedAnchorType === ARAnchorType.FaceAnchor) &&
                                        <ObjectAnchorEditor
                                            arContents={arContents}
                                            anchorType={ARAnchorType.FaceAnchor}
                                            onArContentUpdate={handleUpdateARContent}
                                            onArContentStateUpdate={handleUpdateARContentState}
                                            controlMode={controlMode}
                                        />
                                    }
                                </Card>
                            </Col>
                            <Col lg={2} className="ar-menu-card card-container">
                                <ARContentProperties
                                    arContents={arContents}
                                    pois={displayPois}
                                    onUpdateARContent={handleUpdateARContent}
                                    onUpdateARContentState={handleUpdateARContentState}
                                    onRemoveARContent={handleRemoveARContent}
                                    onUpdatePoi={handleUpdatePoi}
                                    onUpdatePoiState={handleUpdatePoiState}
                                    onRemovePoi={handleRemovePoi}
                                />
                            </Col>
                        </Row>
                    </Container>
                </Suspense>
            }
        </div>
    )
}

const mapStateToProps = (state: ApplicationState): StateProps => {
    return {
        organization: state.Organization,
        editorState: state.Editor,
    }
}

ProjectEditor.displayName = "ProjectEditor"

export { ProjectEditor }
export default connect(mapStateToProps)(ProjectEditor);
