import React, { useEffect } from "react"
import { PageMeta } from "components/Common/Header";

const Thaiverse = () => {

  const openThaiverse = () => {
    const userAgent = navigator.userAgent;

    // Android detection
    if (/android/i.test(userAgent)) {
      window.open(
        'https://play.google.com/store/apps/details?id=com.graffity.thaiversexr',
        '_blank'
      );
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
      window.open(
        'https://apps.apple.com/th/app/thaiverse-xr/id6742598935',
        '_blank'
      );
    } else {
      window.open(
        'https://apps.apple.com/th/app/thaiverse-xr/id6742598935',
        '_blank'
      );
    }


  }

  useEffect(() => {

  }, []);

  return (
    <React.Fragment>
      <div className="" style={{ backgroundColor: '#fff', position: 'relative', height: '100vh', overflow: 'hidden' }}>
        <PageMeta title="TAT Event | Graffity" />
        <div className="text-center my-4"
          style={{ position: 'relative', zIndex: 999, color: '#000' }}
        >
          {/* <div
            style={{
              position: 'absolute',
              // zIndex: 9,
              backgroundImage: 'url(https://s359.kapook.com/pagebuilder/6c1aefd4-dc97-4ed1-949c-c2963647406a.jpg)',
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              opacity: 0.15,
              height: '100px',
              width: '100%',
              top: '-30px',
            }}
          >
          </div> */}

          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '0px' }}>
            <img
              src="https://s359.kapook.com/pagebuilder/6c1aefd4-dc97-4ed1-949c-c2963647406a.jpg"
              alt="Logo"
              style={{ width: '150px', height: '150px', objectFit: 'contain' }}
            />
            <div style={{ textAlign: 'left' }}>
              <h4 className="font-weight-medium" style={{ margin: 0 }}>
                แผนที่งาน TTF 2025
              </h4>
              <h6 className="text-uppercase" style={{ margin: 0 }}>
                นำทาง & แสดงข้อมูลบูธด้วย AR
              </h6>
            </div>
          </div>
        </div>

        <div
          className="map-container"
          style={{ position: 'relative', width: '100%', height: '100%', marginTop: '-100px', zIndex: 0 }}
        >
          <div
            className="overlay text-center"
            style={{
              position: 'absolute',
              top: '0',
              left: '0',
              right: '0',
              background: 'linear-gradient(to top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 35%)',
              padding: '80px 20px', // Adjust padding as needed
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          ></div>
          <iframe
            src="https://app.mappedin.com/map/67e2c3d2656e1c000bc7d494?embedded=true"
            title="Event Map"
            name="Event Map"
            allow="clipboard-write 'self' https://app.mappedin.com; web-share 'self' https://app.mappedin.com"
            width="100%"
            height="100%" // Make iframe take full height of container
            style={{ border: '0' }}
          ></iframe>
          {/* <img
            src="https://medias.thansettakij.com/uploads/images/contents/w1024/2025/03/YyUbVWAYaizN7ehDq89U.webp"
            alt="Amazing Thailand"
            style={{ width: '100%', height: 'auto' }}
          /> */}

          <div
            className="overlay text-center"
            style={{
              position: 'absolute',
              bottom: '0',
              left: '0',
              right: '0',
              background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 50%)',
              padding: '40px 20px', // Adjust padding as needed
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <button
              className="btn btn-primary py-2 px-3"
              onClick={openThaiverse}
              style={{
                zIndex: 1,
                fontSize: "16px",
                backgroundColor: "#EC268F",
                position: 'absolute',
                bottom: '130px',
                margin: '0 40px',
                left: '0',
                right: '0',
              }} // Ensure button is above the gradient
            >
              เปิดแอปเพื่อนำทางด้วย AR <i className="mdi mdi-near-me"></i>
            </button>
          </div>
        </div>

      </div>
    </React.Fragment>
  )
}

export default Thaiverse
